<template>
  <!-- 提料结算 -->
  <div class="keepMaterialJiesuan">
    <template v-if="ishow">
      <van-empty
        class="custom-image"
        :image="require('@/assets/images/pic_no information@2x.png')"
        description="暂无数据!"
      />
    </template>
    <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        v-if="!ishow"
      >
        <!-- 单元格 -->
        <div class="cell" v-for="(item) in list" :key="item.id" @click="ToStockBalance(item)">
          <!-- 左侧标题 -->
          <div class="title" :style="{backgroundColor:item.orderType == 'MORTGAGE'?'#FF7A00':'#33A5FF'}">{{item.orderType == "MORTGAGE"?'尾':'预'}}</div>
          <!-- 内容 -->
          <div class="flex">
            <div class="left">
<!--              <p class="totalWeight">提料总重 :  {{item.incomingWeight.toFixed(2)}}g</p>-->
              <div class="DiscountWeight">提料总重 : {{item.weight.toFixed(2)}}g</div>
              <div class="time">{{item.created | formatDate_}}</div>
            </div>
            <div class="right" :style="{color:item.payStatus =='WAIT_CONFIRM'?'#F52621':item.payStatus =='WAIT'?'#FF7A00':'#04AE8D'}">
              <!--                background:item.payStatus =='WAIT_CONFIRM'?'#FFE6E6':item.payStatus =='WAIT'?'#FFF2E6':'#E6FFFA'-->
              <div class="config">{{item.payStatus =='WAIT_CONFIRM'?'待确认'
                :item.payStatus =='PAYED' && item.orderType=='PRE_MATERIAL'?'待交料'
                  : item.payStatus =='PAYED' && item.orderType=='MATERIAL'?'已收款'
                    : item.payStatus =='WAIT' && item.orderType=='PRE_MATERIAL'? '待付款'
                      : item.payStatus =='WAIT' && item.orderType=='MATERIAL'? '待收款'
                      :'已提料'}}</div>
              <div class="money">{{item.fixedRemainMoney?item.fixedRemainMoney >0?'+'+item.fixedRemainMoney.toFixed(2):item.fixedRemainMoney.toFixed(2):item.money>0?'+'+item.money.toFixed(2):item.money}}</div>
            </div>
          </div>
          <!-- 右侧箭头 -->
          <van-icon name="arrow"/>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import *as api from'@/utils/api'
export default {
  data() {
    return {
      list:[],
      loading: false,
      finished: false,
      refreshing: false,
      ishow: true
    }
  },
  async created(){
    this.onLoad();
  },
  methods:{
    // 单元格点击事件
    ToStockBalance(item){
      this.$router.push({
        name:'materialBalance',
        query:{
          id:item.id,
          payStatus:item.payStatus,
          orderType:item.orderType
        }
      })
    },
    async onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      try{
        let res = await api.getKeepMaterialJieSuanList()
        this.list = res
        this.finished = true;
        this.loading = false;
        if( this.list.length === 0){
          this.ishow = true;
        } else {
          this.ishow = false;
        }
      } catch {
        this.ishow = true;
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-title {
  margin-right: 4px;
  vertical-align: middle;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}
.cell{
  margin: 24px;
  background: #ffffff;
  padding:  20px 40px 20px 100px;
  border-top: 1px solid #f5f6f7;
  //width: 100%;
  position:relative;
  .van-icon{
    font-size: 26px;
    color:#c4c4c4;
    position: absolute;
    top: 66px;
    right: 10px;
  }
  .title{
    position: absolute;
    top: 40px;
    left: 22px;
    width: 65px;
    height: 65px;
    text-align: center;
    line-height: 65px;
    border-radius: 50%;
    font-size: 28px;
    color: #fff;
  }
  .flex{
    display: flex;
    justify-content: space-between;
    .right {
      padding-top: 26px;
    }
  }
  p{
    font-size: 28px;
  }
  .time{
    font-size: 24px;
    color: #8F8F9F;
  }
  .totalWeight{
    color: #39394D;
    font-size: 28px;
    font-weight: 500;
  }
  .DiscountWeight{
    color: #8F8F9F;
    margin: 6px 0;
    font-size: 28px;
  }
  .config{
    //margin-bottom: 8px;
    font-size: 24px;
    text-align: right;
  }
}
.keepMaterialJiesuan{
  background: #F1F3F7;
}
</style>