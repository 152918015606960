<template>
  <!-- 存料提纯 -->
  <div class="materialOrder">
    <van-tabs v-model="active" animated sticky title-active-color="#F6D0AB" title-inactive-color="#39394D" type="card">
      <van-tab title="提料管理">
        <keep-alive>
          <keepMaterialAdmin />
        </keep-alive>
      </van-tab>
      <van-tab title="提料结算">
        <keep-alive>
          <keepMaterialJiesuan />
        </keep-alive>
      </van-tab>
    </van-tabs>
    <!-- 左侧图标 -->
    <div class="tabsLeftIcon" @click="ToPerson">
      <van-icon name="arrow-left" color="#F6D0AB" />
    </div>
    <div class="top" v-if="active =='0'?true:false">
      <div class="startTime">开始时间</div>
      <div class="weight">重量(g)</div>
      <div class="realTlmeMOney">实时应收(元)</div>
      <div class="state">状态</div>
    </div>
  </div>
</template>

<script>
import keepMaterialAdmin from './components/keepMaterialAdmin.vue'
import keepMaterialJiesuan from './components/keepMaterialJiesuan.vue'
export default {
  components: {
    keepMaterialAdmin,
    keepMaterialJiesuan
  },
  data() {
    return {
      active:'0'
    }
  },
  mounted() {
    this.$route.query.active ? this.active = this.$route.query.active:this.active = '0'
  },
  methods: {
    ToPerson() {
      this.$router.push({
        name: "person"
      });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{

  .van-tabs__wrap{
    height: 88px;
    padding: 0 220px;
    background-color: #39394D;
  }
  .van-tabs__line{
    display: none;
  }
  .van-tabs__nav--line {
    padding-bottom: 0 !important;
  }
  .van-tabs__nav{
    //border: 1px solid #ff2d2e;
    height: 50px;
    margin-top: 16px;
    border-radius: 10px ;
  }
  .van-tab{
    height: 100%;
    color: #ff2d2e;
    line-height: 50px;
    font-size: 28px;
    padding: 0;
  }
  .van-tabs__nav--card .van-tab--active {
    background:  #39394D !important;
    border-radius: 20px;
  }
  .van-tabs__nav--card .van-tab {
    border-right: none;
  }
  .van-tabs__nav--card {
    border: none;
    border-radius: 100px;
    padding: 2px;
  }
/*  .van-tab--active{
    height: 100%;
    line-height: 50px;
    font-size: 28px;
    padding: 0;
    background-color: #ff2d2e;
    color: #fff;
  }*/
}
.tabsLeftIcon{
  position: fixed;
  top: 30px;
  left: 10px;
  z-index: 9999;
  .van-icon{
    font-size: 32px;
  }
}
.materialOrder {
  background: #F1F3F7;
  height: 100%;
}
.top{
  width: 710px;
  display: flex;
  position: fixed;
  top: 100px;
  left: 20px;
  padding: 14px 0;
  background-color: #ffffff;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  color: #919191;
  //font-weight: 600;
  //background-color: #fff;
  text-align: center;
  font-size: 28px;
  .startTime {
    margin-left: 30px;
  }
  .weight {
    margin-left: 20px;
  }
  .realTlmeMOney{
    margin-left: 20px;

  }
  .state {
    margin-right: 30px;
  }
}
</style>